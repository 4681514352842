<template>
    <div class="container-fluid">
        <div class="card my-3">
            <div class="card-header d-inline-flex justify-content-between">
                <AdminSideBarToggle/>
                <el-input
                    style="max-width: 250px"
                    class="ms-auto me-4"
                    clearable
                    v-model="searchText"
                    prefix-icon="el-icon-search"
                    :placeholder="$t('search')"/>
            </div>
            <!-- Accounts table -->
            <div class="card-body">
                <el-table
                    v-loading="accountsLoadingState"
                    :data="filteredAccounts"
                    style="width: 100%">
                    <el-table-column
                        prop="email"
                        fixed="left"
                        min-width="150px"
                        label="Email"/>
                    <el-table-column
                        prop="number"
                        fixed="left"
                        min-width="80px"
                        :label="$t('account')"/>
                    <el-table-column
                        prop="name"
                        min-width="60px"
                        :label="$t('name')"/>
                    <el-table-column
                        prop="balance"
                        min-width="100px"
                        :label="$t('balance')"/>
                    <el-table-column
                        prop="deposit"
                        min-width="100px"
                        :label="$t('deposit')"/>
                    <el-table-column
                        prop="company"
                        min-width="150px"
                        :label="$t('company')"/>
                    <el-table-column
                        prop="currency"
                        min-width="100px"
                        :label="$t('currency')"/>
                    <el-table-column
                        prop="equity"
                        min-width="100px"
                        :label="$t('equity')"/>
                    <el-table-column
                        prop="free_margin"
                        min-width="100px"
                        :label="$t('free_margin')"/>
<!--                    <el-table-column-->
<!--                        prop="free_margin_mode"-->
<!--                        min-width="100px"-->
<!--                        :label="$t('free_margin_mode')"/>-->
                    <el-table-column
                        prop="leverage"
                        min-width="80px"
                        :label="$t('leverage')"/>
                    <el-table-column
                        prop="margin"
                        min-width="100px"
                        :label="$t('margin')"/>
                    <el-table-column
                        prop="profit"
                        min-width="100px"
                        :label="$t('profit')"/>
                    <el-table-column
                        prop="server"
                        min-width="150px"
                        :label="$t('server')"/>
                    <el-table-column
                        prop="stopout_level"
                        min-width="150px"
                        :label="$t('stopout_level')"/>
<!--                    <el-table-column-->
<!--                        prop="stopout_mode"-->
<!--                        min-width="50px"-->
<!--                        :label="$t('stopout_mode')"/>-->
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>

import AdminSideBarToggle from '@/components/AdminSideBarToggle'

export default {
    name: "Accounts",
    components: {
        AdminSideBarToggle,
    },
    i18n: {
        messages: {
            en: {
                accounts: 'Accounts',
                account: 'Account',
                search: 'Search',
                edit: 'Edit',
                close: 'Close',
                save: 'Save',
                actions: 'Actions',
                name: 'Name',
                balance: 'Balance',
                deposit: 'Deposit',
                company: 'Company',
                currency: 'Currency',
                equity: 'Equity',
                free_margin: 'Free margin',
                leverage: 'Leverage',
                margin: 'Margin',
                profit: 'Profit',
                server: 'Server',
                stopout_level: 'Stop Out level',
            },
            ru: {
                accounts: 'Счета',
                account: 'Счет',
                search: 'Поиск',
                edit: 'Редактировать',
                close: 'Закрыть',
                save: 'Сохранить',
                actions: 'Действия',
                name: 'Имя',
                balance: 'Баланс',
                deposit: 'Депозит',
                company: 'Брокер',
                currency: 'Валюта',
                equity: 'Средства',
                free_margin: 'Свободная маржа',
                leverage: 'Плечо',
                margin: 'Маржа',
                profit: 'Профит',
                server: 'Сервер',
                stopout_level: 'Уровень Stop Out',
            }
        }
    },
    data: function () {
        return {
            searchText: '',
        }
    },
    computed: {
        accountsLoadingState: function () {
            return this.$store.getters['admin/accountsLoadingState']
        },
        accounts: function () {
            return this.$store.getters['admin/accounts']
        },
        filteredAccounts: function () {
            return this.accounts.filter(account => {
                return account.email.toUpperCase().includes(this.searchText.toUpperCase()) ||
                    account.name.toUpperCase().includes(this.searchText.toUpperCase()) ||
                    account.number.toString().includes(this.searchText)
            })
        },
    },
    methods: {
        reloadAccounts: function () {
            this.$store.dispatch('admin/accountsGetRequest')
            .catch(() => {
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    message: 'Accounts loading error'
                })
            })
        },
    },
    mounted() {
        this.reloadAccounts()
    }
}
</script>

<style scoped>

</style>
